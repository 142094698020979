var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        value: _vm.value,
        placeholder: _vm.$t("lbl_choose"),
        "allow-clear": "",
        disabled: _vm.disabled,
        loading: _vm.loading,
        "dropdown-match-select-width": false
      },
      on: { select: _vm.onSelect }
    },
    [
      _c("a-icon", {
        attrs: { slot: "clearIcon", type: "close-circle", theme: "filled" },
        on: { click: _vm.clear },
        slot: "clearIcon"
      }),
      _vm._l(_vm.dtOpt, function(item) {
        return _c(
          "a-select-option",
          { key: item.value, attrs: { value: item.value } },
          [
            _c(
              "a-tooltip",
              [
                _c("template", { slot: "title" }, [
                  _vm._v(" " + _vm._s(item.key || "-") + " ")
                ]),
                _vm._v(" " + _vm._s(item.key || "-") + " ")
              ],
              2
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }